import { Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef as OverlayRefCDK } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ImpactOverlayRef } from './overlay-ref';
import { ImpactOverlayConfig } from './overlay.interfaces';
import { IMPACT_OVERLAY_DATA } from './overlay.tokens';

const DEFAULT_CONFIG: ImpactOverlayConfig = {
    hasBackdrop: true,
    blockScroll: true,
    backdropClass: 'cdk-overlay-dark-backdrop',
    panelClass: '',
    positionVertical: {
        placement: 'center',
    },
    positionHorizontal: {
        placement: 'center',
    },
    data: {},
};

@Injectable({ providedIn: 'root' })
export class OverlayService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    /**
     * Internal holder of {ImpactOverlayRef}
     */
    readonly refs: ImpactOverlayRef[] = [];

    constructor(
        private readonly overlay: Overlay,
        private readonly injector: Injector
    ) {}

    positionStrategies: OverlayPositionBuilder = this.overlay.position();

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    open(component: any, config: ImpactOverlayConfig = {}) {
        const dialogConfig = { ...DEFAULT_CONFIG, ...config };

        const overlayRef = this.createOverlay(dialogConfig);
        const dialogRef = new ImpactOverlayRef(overlayRef);

        const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef, component);

        dialogRef.componentInstance = overlayComponent;

        this.refs.push(dialogRef);

        dialogRef
            .beforeClose()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe(() => {
                // move focus back by removing inert from page
                dialogRef.toggleInertOnTree('ncg-root', false, 'ncg-header');

                this.refs.splice(this.refs.indexOf(dialogRef), 1);
            });

        overlayRef
            .backdropClick()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => dialogRef.close());

        // inert the page so focus is moved
        dialogRef.toggleInertOnTree('ncg-root', true, 'ncg-header');

        return dialogRef;
    }

    /**
     * Close all panes what so ever
     */
    closeAll(): void {
        if (this.refs.length > 0) {
            this.refs.forEach((child) => child.close());
            this.refs.splice(0);
        }
    }

    getOverlayConfig(config: ImpactOverlayConfig): ImpactOverlayConfig {
        let positionStrategy = config.positionStrategy;

        if (!positionStrategy) {
            const globalPositionStrategy = this.overlay.position().global();

            if (config.positionHorizontal) {
                switch (config.positionHorizontal.placement) {
                    case 'left':
                        config.positionHorizontal.offset
                            ? globalPositionStrategy.left(config.positionHorizontal.offset)
                            : globalPositionStrategy.left();
                        break;
                    case 'right':
                        config.positionHorizontal.offset
                            ? globalPositionStrategy.right(config.positionHorizontal.offset)
                            : globalPositionStrategy.right();
                        break;
                    case 'center':
                        config.positionHorizontal.offset
                            ? globalPositionStrategy.centerHorizontally(config.positionHorizontal.offset)
                            : globalPositionStrategy.centerHorizontally();
                        break;
                }
            }

            if (config.positionVertical) {
                switch (config.positionVertical.placement) {
                    case 'top':
                        config.positionVertical.offset ? globalPositionStrategy.top(config.positionVertical.offset) : globalPositionStrategy.top();
                        break;
                    case 'bottom':
                        config.positionVertical.offset
                            ? globalPositionStrategy.bottom(config.positionVertical.offset)
                            : globalPositionStrategy.bottom();
                        break;
                    case 'center':
                        config.positionVertical.offset
                            ? globalPositionStrategy.centerVertically(config.positionVertical.offset)
                            : globalPositionStrategy.centerVertically();
                        break;
                }
            }

            positionStrategy = globalPositionStrategy;
        }

        let scrollStrategy = config.scrollStrategy;

        if (!scrollStrategy) {
            scrollStrategy = config.blockScroll ? this.overlay.scrollStrategies.block() : this.overlay.scrollStrategies.noop();
        }

        const panelClass = Array.isArray(config.panelClass) ? [...config.panelClass] : config.panelClass ? [config.panelClass] : [];

        panelClass.unshift('impact-dialog-container');
        panelClass.push('impact-panel');

        if (config.fullHeight) {
            panelClass.push('impact-panel--full-height');
        }

        if (config.fullWidth) {
            panelClass.push('impact-panel--full-width');
        }

        return new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass,
            scrollStrategy,
            positionStrategy,
        });
    }

    createOverlay(config: ImpactOverlayConfig) {
        const overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    }

    createInjector(config: ImpactOverlayConfig, dialogRef: ImpactOverlayRef): PortalInjector {
        const injectionTokens = new WeakMap();

        injectionTokens.set(ImpactOverlayRef, dialogRef);
        injectionTokens.set(IMPACT_OVERLAY_DATA, config.data);

        return new PortalInjector(this.injector, injectionTokens);
    }

    attachDialogContainer(overlayRef: OverlayRefCDK, config: ImpactOverlayConfig, dialogRef: ImpactOverlayRef, component: any) {
        const injector = this.createInjector(config, dialogRef);

        const containerPortal = new ComponentPortal(component, null, injector);
        const containerRef: ComponentRef<any> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }
}
