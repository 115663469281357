import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

let dialogElementUid = 0;

export class ImpactOverlayRef {
    readonly id: string = `impact-dialog-${dialogElementUid++}`;

    componentInstance: any;

    private _beforeClose$: Subject<any> = new Subject<any>();
    private _afterClose$: Subject<any> = new Subject<any>();

    constructor(private readonly overlayRef: OverlayRef) {}

    cdkRef: OverlayRef = this.overlayRef;

    close(dialogResult?: any): void {
        if (this.componentInstance.animationStateChanged) {
            /**
             * Detaching the overlay
             */
            this.componentInstance.animationStateChanged
                .pipe(
                    filter((event: AnimationEvent) => event.phaseName === 'start'),
                    take(1)
                )
                .subscribe(() => {
                    this._beforeClose$.next(dialogResult);
                    this._beforeClose$.complete();
                    this.overlayRef.detachBackdrop();
                });

            /**
             * Disposing the overlay and cleaning up
             */
            this.componentInstance.animationStateChanged
                .pipe(
                    filter((event: AnimationEvent) => event.phaseName === 'done' && event.toState === 'leave'),
                    take(1)
                )
                .subscribe(() => {
                    this.overlayRef.dispose();
                    this._afterClose$.next(dialogResult);
                    this._afterClose$.complete();

                    // Make sure to also clear the reference to the
                    // component instance to avoid memory leaks
                    this.componentInstance = null;
                });

            this.componentInstance.startExitAnimation();
        } else {
            this._beforeClose$.next(dialogResult);
            this._beforeClose$.complete();

            this.overlayRef.dispose();

            this._afterClose$.next(dialogResult);
            this._afterClose$.complete();
        }
    }

    beforeClose(): Observable<any> {
        return this._beforeClose$.asObservable();
    }

    afterClose(): Observable<any> {
        return this._afterClose$.asObservable();
    }

    /**
     * Recursively toggles the "inert" attribute on elements under the given root,
     * applying it only to those elements that are not part of the branch leading to
     * the excluded element.
     *
     * In the given DOM tree, if an element does not have the excluded element (or one
     * of its descendants) anywhere in its branch, then the entire branch is set to
     * inert (if setInert is true) or cleared of inert. If a branch does contain the
     * excluded element, then that branch is "peeled" to leave the elements that are not
     * on the branch to the excluded element marked inert.
     *
     * For example, using this HTML structure:
     *
     * @example
     * <main>
     *   <div class="element1"></div>
     *   <div class="element2">
     *     <div class="element3"></div>
     *     <div class="element4">
     *       <div class="element5"></div>
     *       <div class="element6"></div>
     *     </div>
     *     <div class="element7">
     *       <div class="element8"></div>
     *     </div>
     *   </div>
     *   <div class="element9"></div>
     * </main>
     *
     * And calling:
     *
     *   this.toggleInertOnTree('main', true, '.element5');
     *
     * The resulting DOM will be:
     *
     * <main>
     *   <div class="element1" inert="true"></div>
     *   <div class="element2">
     *     <div class="element3" inert="true"></div>
     *     <div class="element4">
     *       <div class="element5"></div>
     *       <div class="element6" inert="true"></div>
     *     </div>
     *     <div class="element7" inert="true">
     *       <div class="element8"></div>
     *     </div>
     *   </div>
     *   <div class="element9" inert="true"></div>
     * </main>
     *
     * @param {string} rootSelector - A CSS selector for the root element under which inert should be applied.
     * @param {boolean} [setInert=false] - If true, the method sets "inert" on elements (if false, it removes "inert").
     * @param {string} [excludeSelector] - A CSS selector for an element whose branch (itself and its descendants) should be excluded from inerting.
     */
    public toggleInertOnTree(rootSelector: string, setInert: boolean = false, excludeSelector?: string): void {
        const root = document.querySelector(rootSelector);
        const exclude = excludeSelector ? document.querySelector(excludeSelector) : null;

        if (!root) {
            console.warn(`Root element not found for selector: ${rootSelector}`);
            return;
        }

        /**
         * Helper: Checks if the given element's branch contains the excluded element.
         *
         * @param {Element} el - Element to check.
         * @returns {boolean} True if the excluded element is found within el's subtree.
         */
        const containsExclude = (el: Element): boolean => (exclude ? el.contains(exclude) : false);

        /**
         * Processes a branch that contains the excluded element.
         * Leaves the branch along the path to the excluded element unchanged and applies inert
         * to any direct siblings (and their subtrees) that do not lead to the excluded element.
         *
         * @param {Element} el - The element whose children are to be examined.
         */
        const processExcludedBranch = (el: Element): void => {
            Array.from(el.children).forEach((child) => {
                // If this child is exactly the excluded element, skip inerting it.
                if (child === exclude) {
                    return;
                }
                // If this child contains the excluded element, continue processing its subtree.
                if (containsExclude(child)) {
                    processExcludedBranch(child);
                } else {
                    // Otherwise, inert this child (the inert attribute covers its entire subtree).
                    if (setInert) {
                        child.setAttribute('inert', 'true');
                    } else {
                        child.removeAttribute('inert');
                    }
                }
            });
        };

        // Process each direct child of the root.
        Array.from(root.children).forEach((child) => {
            if (containsExclude(child)) {
                // This branch contains the excluded element; process it to inert only the parts not leading to it.
                processExcludedBranch(child);
            } else {
                // This branch does not lead to the excluded element; mark the entire branch inert.
                if (setInert) {
                    child.setAttribute('inert', 'true');
                } else {
                    child.removeAttribute('inert');
                }
            }
        });
    }
}
